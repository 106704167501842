import React from 'react';
import PageLayout from "../../layouts/page_layout";
import GridsContent from "../../components/pages_content/grids_content";
export default function Grids() {
    return (
        <>
            <PageLayout>
                <GridsContent/>
            </PageLayout>
        </>
    )
}
