import React from 'react';
import PageLayout from "../layouts/page_layout";
import ResetPasswordContent from "../components/pages_content/reset_password_content";
export default function ResetPassword() {
    return (
        <PageLayout>
            <ResetPasswordContent/>
        </PageLayout>
    )
}
