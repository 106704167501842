import React from 'react';
import PageLayout from "../layouts/page_layout";
import LoginContent from "../components/pages_content/login_content";

export default function Login() {
    
    return (
        <PageLayout>
            <LoginContent/>
        </PageLayout>
    )
}
