import React from 'react';
import PageLayout from "../layouts/page_layout";
import FourZeroFourContent from "../components/pages_content/four_zero_four_content";

export default function FourZeroFour() {
    return (
        <PageLayout>
            <FourZeroFourContent/>
        </PageLayout>
    )
}
