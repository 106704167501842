import React from 'react';
import PageLayout from "../../layouts/page_layout";
import ButtonsContent from "../../components/pages_content/buttons_content";
export default function Buttons() {
    return (
        <PageLayout>
            <ButtonsContent/>
        </PageLayout>
    )
}
