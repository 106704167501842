import React from 'react'

export default function Footer() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <p className="copyright">&copy; Copyright 2020 by Tortoiz. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    )
}
