import React from 'react'

export default function GridsContent() {
    return (
        <div className="content-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="page-title">Grids</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row title">
                    <div className="col-lg-12">
                        <h2>Based on Bootstrap grid system</h2>
                    </div>
                </div>
                <div className="row grid">
                    <div className="col-lg-12">
                        <div className="grid-col">.col-lg-12</div>
                    </div>
                </div>
                <div className="row grid">
                    <div className="col-lg-6 ">
                        <div className="grid-col">.col-lg-6</div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="grid-col">.col-lg-6</div>
                    </div>
                </div>
                <div className="row grid">
                    <div className="col-lg-4 ">
                        <div className="grid-col">.col-lg-4</div>
                    </div>
                    <div className="col-lg-4 ">
                        <div className="grid-col">.col-lg-4</div>
                    </div>
                    <div className="col-lg-4 ">
                        <div className="grid-col">.col-lg-4</div>
                    </div>
                </div>
                <div className="row grid">
                    <div className="col-lg-2 ">
                        <div className="grid-col">.col-lg-2</div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="grid-col">.col-lg-2</div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="grid-col">.col-lg-2</div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="grid-col">.col-lg-2</div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="grid-col">.col-lg-2</div>
                    </div>
                    <div className="col-lg-2 ">
                        <div className="grid-col">.col-lg-2</div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row title">
                    <div className="col-lg-12">
                        <h2>Mobile Tablet and Desktop</h2>
                    </div>
                </div>
                <div className="row grid">
                    <div className="col-lg-3 ">
                        <div className="grid-col">.col-xs-3</div>
                    </div>
                    <div className="col-lg-3 ">
                        <div className="grid-col">.col-xs-3</div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="grid-col">.col-xs-6</div>
                    </div>
                </div>
                <div className="row grid">
                    <div className="col-lg-3 ">
                        <div className="grid-col">.col-sm-3</div>
                    </div>
                    <div className="col-lg-3 ">
                        <div className="grid-col">.col-sm-3</div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="grid-col">.col-sm-6</div>
                    </div>
                </div>
                <div className="row grid">
                    <div className="col-lg-4 ">
                        <div className="grid-col">.col-md-4</div>
                    </div>
                    <div className="col-lg-4 ">
                        <div className="grid-col">.col-md-4</div>
                    </div>
                    <div className="col-lg-4 ">
                        <div className="grid-col">.col-md-4</div>
                    </div>
                </div>
                <div className="row grid">
                    <div className="col-lg-6 ">
                        <div className="grid-col">.col-sm-6</div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="grid-col">.col-sm-6</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
