import React from 'react';
import FormsContent from "../../components/pages_content/forms_content";
import PageLayout from "../../layouts/page_layout";

export default function FormsPage() {
    return (
        <>
            <PageLayout>
                <FormsContent/>
            </PageLayout>
        </>
    )
}
