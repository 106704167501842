import React from 'react';
import PageLayout from "../layouts/page_layout";
import HomeDefault from "../components/home_default";
export default function Home() {
    return (
        <PageLayout>
            <HomeDefault/>
        </PageLayout>
    )
}
