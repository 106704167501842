import React from 'react'
import Pagelayout from "../layouts/page_layout";
import BuySellContent from "../components/pages_content/buy_sell_content";
export default function BuySell() {
    return (
        <>
            <Pagelayout>
                <BuySellContent/>
            </Pagelayout>
        </>
    )
}
