import React from 'react';
import PageLayout from "../layouts/page_layout";
import MarketcapContent from "../components/pages_content/marketcap_content";
export default function Marketcap() {
    return (
        <>
            <PageLayout>
                <MarketcapContent/>
            </PageLayout>
        </>
    )
}
