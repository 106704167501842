import React from 'react';
import PageLayout from "../layouts/page_layout";
import ExchangeContent from "../components/pages_content/exchange_content";

export default function CurrencyExchange() {
    return (
        <>
            <PageLayout>
                <ExchangeContent/>
            </PageLayout>
        </>
    )
}
