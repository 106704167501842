import React from 'react';
import PageLayout from "../layouts/page_layout";
import RegisterContent from "../components/pages_content/register_content";
export default function Register() {
    return (
        <PageLayout>
            <RegisterContent/>
        </PageLayout>
    )
}
