import React from 'react';
import PageLayout from "../layouts/page_layout";
import WalletContent from "../components/pages_content/wallet_content";
export default function Wallet() {
    return (
       <>
        <PageLayout>
            <WalletContent/>
        </PageLayout>
       </>
    )
}
