import React from 'react'

export default function ButtonsContent() {
    return (
        <div className="content-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="page-title">Buttons</h2>
                    </div>
                </div>
            </div>
            <div className="buttons-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="button-groups">
                                <div className="button-catagory flat-square-buttons m-b-30">
                                    <h4 className="m-b-20">Flat Square buttons</h4>
                                    <button type="button" className="custom-btn button btn-primary btn-flat">Primary</button>
                                    <button type="button" className="custom-btn button btn-hover btn-flat">Hover</button>
                                    <button type="button" className="custom-btn button btn-success btn-flat">Success</button>
                                    <button type="button" className="custom-btn button btn-danger btn-flat">Danger</button>
                                    <button type="button" className="custom-btn button btn-button btn-flat">Button</button>
                                    <button type="button" className="custom-btn button btn-link btn-flat">Link</button>
                                </div>
                                <div className="button-catagory raised-square-buttons m-b-30">
                                    <h4 className="m-b-20">Raised Square buttons</h4>
                                    <button type="button" className="custom-btn button btn-primary btn-flat btn-raised">Primary</button>
                                    <button type="button" className="custom-btn button btn-hover btn-flat btn-raised">Hover</button>
                                    <button type="button" className="custom-btn button btn-success btn-flat btn-raised">Success</button>
                                    <button type="button" className="custom-btn button btn-danger btn-flat btn-raised">Danger</button>
                                    <button type="button" className="custom-btn button btn-button btn-flat btn-raised">Button</button>
                                    <button type="button" className="custom-btn button btn-flat btn-link btn-raised">Link</button>
                                </div>
                                <div className="button-catagory radius-square-buttons m-b-30">
                                    <h4 className="m-b-20">Corner Radias Square buttons</h4>
                                    <button type="button" className="custom-btn button btn-primary btn-radius">Primary</button>
                                    <button type="button" className="custom-btn button btn-hover btn-radius">Hover</button>
                                    <button type="button" className="custom-btn button btn-success btn-radius">Success</button>
                                    <button type="button" className="custom-btn button btn-danger btn-radius">Danger</button>
                                    <button type="button" className="custom-btn button btn-button btn-radius">Button</button>
                                    <button type="button" className="custom-btn button btn-radius btn-link">Link</button>
                                </div>
                                <div className="button-catagory outline-square-buttons m-b-30">
                                    <h4 className="m-b-20">Outline Square buttons</h4>
                                    <button type="button" className="custom-btn button btn-outline-primary btn-radius btn-outline">Primary</button>
                                    <button type="button" className="custom-btn button btn-outline-hover btn-radius btn-outline">Hover</button>
                                    <button type="button" className="custom-btn button btn-outline-success btn-radius btn-outline">Success</button>
                                    <button type="button" className="custom-btn button btn-outline-danger btn-radius btn-outline">Danger</button>
                                    <button type="button" className="custom-btn button btn-outline-button btn-radius btn-outline">Button</button>
                                    <button type="button" className="custom-btn button btn-radius btn-outline-link btn-outline">Link</button>
                                </div>
                                <div className="button-catagory flat-circular-buttons m-b-30">
                                    <h4 className="m-b-20">Flat Circular buttons</h4>
                                    <button type="button" className="custom-btn button btn-primary btn-circular">Primary</button>
                                    <button type="button" className="custom-btn button btn-hover btn-circular">Hover</button>
                                    <button type="button" className="custom-btn button btn-success btn-circular">Success</button>
                                    <button type="button" className="custom-btn button btn-danger btn-circular">Danger</button>
                                    <button type="button" className="custom-btn button btn-button btn-circular">Button</button>
                                    <button type="button" className="custom-btn button btn-link btn-circular">Link</button>
                                </div>
                                <div className="button-catagory flat-circular-buttons m-b-30">
                                    <h4 className="m-b-20">Raised Flat Circular buttons</h4>
                                    <button type="button" className="custom-btn button btn-primary btn-circular btn-raised">Primary</button>
                                    <button type="button" className="custom-btn button btn-hover btn-circular btn-raised">Hover</button>
                                    <button type="button" className="custom-btn button btn-success btn-circular btn-raised">Success</button>
                                    <button type="button" className="custom-btn button btn-danger btn-circular btn-raised">Danger</button>
                                    <button type="button" className="custom-btn button btn-button btn-circular btn-raised">Button</button>
                                    <button type="button" className="custom-btn button btn-link btn-circular btn-raised">Link</button>
                                </div>
                                <div className="button-catagory flat-circular-buttons m-b-30">
                                    <h4 className="m-b-20">Outline Circular buttons</h4>
                                    <button type="button" className="custom-btn button btn-outline-primary btn-circular btn-outline">Primary</button>
                                    <button type="button" className="custom-btn button btn-outline-hover btn-circular btn-outline">Hover</button>
                                    <button type="button" className="custom-btn button btn-outline-success btn-circular btn-outline">Success</button>
                                    <button type="button" className="custom-btn button btn-outline-danger btn-circular btn-outline">Danger</button>
                                    <button type="button" className="custom-btn button btn-outline-button btn-circular btn-outline">Button</button>
                                    <button type="button" className="custom-btn button btn-outline-link btn-circular btn-outline">Link</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
