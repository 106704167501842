import React from 'react';
import PageLayout from "../layouts/page_layout";
import RecoverPasswordContent from "../components/pages_content/recover_password_content";
export default function RecoverPassword() {
    return (
        <PageLayout>
            <RecoverPasswordContent/>
        </PageLayout>
    )
}
