import React from 'react';
import PageLayout from "../layouts/page_layout";
import IconContent from "../components/pages_content/ico_content";
export default function Ico() {
    return (
        <>
            <PageLayout>
                <IconContent/>
            </PageLayout>  
        </>
    )
}
