import React from 'react';

export default function CustomPagination() {
    return (
        <div className="content-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="page-title">Pagination</h2>
                    </div>
                </div>
            </div>
            <div className="pagination-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-data">
                                <div className="card rounded-0">
                                    <div className="card-header">
                                        <h4 className="mb-0">
                                            Basic Pagination styles
                                        </h4>
                                    </div>
                                    <div id="basic-form" className="collapse show">
                                        <div className="card-body pagination-area">
                                            <nav aria-label="Page">
                                                <ul className="pagination pagination-flat">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Previous</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link active" href="#">1</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">2</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">3</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">...</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">188</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <nav aria-label="Page">
                                                <ul className="pagination pagination-circular">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Previous</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link active" href="#">1</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">2</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">3</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">...</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">188</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <nav aria-label="Page">
                                                <ul className="pagination pagination-flat pagination-bordered">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Previous</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link active" href="#">1</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">2</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">3</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">...</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">188</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <nav aria-label="Page">
                                                <ul className="pagination pagination-rounded">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Previous</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link active" href="#">1</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">2</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">3</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">...</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">188</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <nav aria-label="Page">
                                                <ul className="pagination pagination-rounded pagination-bordered">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Previous</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link active" href="#">1</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">2</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">3</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">...</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">188</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <nav aria-label="Page">
                                                <ul className="pagination pagination-rounded">
                                                    <li className="page-item">
                                                        <a className="page-link active" href="#">Previous</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <nav aria-label="Page">
                                                <ul className="pagination pagination-flat">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Previous</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link active" href="#">Next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <nav aria-label="Page">
                                                <ul className="pagination pagination-circular">
                                                    <li className="page-item">
                                                        <a className="page-link active" href="#">Previous</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
