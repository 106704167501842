import React from 'react';
import PageLayout from "../../layouts/page_layout";
import TabsContent from "../../components/pages_content/tabs_content";
export default function Tabs() {
    return (
        <>
            <PageLayout>
                <TabsContent/>
            </PageLayout>
        </>
    )
}
