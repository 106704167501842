import React from 'react';
import PageLayout from "../../layouts/page_layout";
import TablesContent from "../../components/pages_content/tables_content";
export default function Tables() {
    return (
        <PageLayout>
            <TablesContent/>
        </PageLayout>
    )
}
