import React from 'react';
import PageLayout from "../layouts/page_layout";
import FaqContent from "../components/pages_content/faq_content";

export default function faq() {
    return (
      <>
        <PageLayout>
          <FaqContent/>
        </PageLayout>
      </>
    )
}
