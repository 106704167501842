import React from 'react';
import PageLayout from "../layouts/page_layout";
import ChartsContent from "../components/pages_content/charts_content";

export default function Charts() {
    return (
        <>
            <PageLayout>
                <ChartsContent/>
            </PageLayout>
        </>
    )
}
