import React from 'react';
import PageLayout from "../layouts/page_layout";
import TradingContent from "../components/pages_content/trading_content";
export default function Trading() {
    return (
        <>
          <PageLayout>
            <TradingContent/>
          </PageLayout>
       </>
    )
}
